















































import { Component, Vue } from 'vue-property-decorator';
import {
  ICategory,
  ICategoryCreate,
} from '@/interfaces';
import { dispatchGetCategories, dispatchBulkUpdateCategories } from '@/store/admin/actions';
import { readCategories } from '@/store/admin/getters';
import { ttt } from '@/nutils';

@Component
export default class AdminCategory extends Vue {
  public ttt = ttt;
  public valid = false;

  public cats: string[] = [ ];

  public async mounted() {
    await dispatchGetCategories(this.$store);
    this.reset();
  }

  public reset() {
    this.cats = readCategories(this.$store).map(x => x.title);
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      // tslint:disable-next-line:no-console
      // console.log(this.cats);
      await dispatchBulkUpdateCategories(this.$store, this.cats);
      this.$router.push('/main/admin/admindashboard');
    }
  }

  public remove(item) {
    // tslint:disable-next-line:no-console
    // console.log('quito ' + item);
    this.cats.splice(this.cats.indexOf(item), 1)
    this.cats = [...this.cats]
  }
  public add(item) {
    // tslint:disable-next-line:no-console
    // console.log('queda ' + this.cats);
  }
}
